html {
  background-color: #1d2833;
}

.MuiTableCell-root {
  height: 14px !important;
  padding: 8px !important;
  padding-left: 24px !important;
  font-size: 14px !important;
}

.MuiTableCell-footer.MuiTableCell-root {
  height: 60px !important;
}

/* .MuiButtonBase-root{
    height: 10px !important;
} */

.flex-column-oriented {
  display: flex;
  flex-direction: column;
}

.MuiTypography-body1.tipography-font-size {
  font-size: 0.75rem;
}

.citation-section {
  border-top: 1px solid #c0c0c0;
  border-left: 1px solid #c0c0c0;
  border-right: 1px solid #c0c0c0;
  padding: 8px;
  /* padding-left: 8px;
	padding-right: 8px;
	padding-bottom: 8px; */
}

.citation-section-last {
  border-bottom: 1px solid #c0c0c0;
}

.full-width {
  width: 100%;
}

.mr-16 {
  margin-right: 16px;
}

.transparent75 {
  color: rgba(0, 0, 0, 0.5);
}

.fade-in {
  transition: opacity 0.5s;
  opacity: 1;
}

.fadeborder {
  border-bottom: 8px solid transparent;
  transition: border-bottom 0.5s;
  text-decoration: none;
}
.fadeborder:hover {
  border-bottom-color: #e8c44c;
}
.fadebordersmall {
  border-bottom: 4px solid transparent;
  transition: border-bottom 0.5s;
  text-decoration: none;
}
.fadebordersmall:hover {
  border-bottom-color: #e8c44c;
}
/* 
  .fadeit {
    border-bottom: '0px';

    -webkit-transition:  border 3s ease;
    -moz-transition:  border 3s ease;
    -o-transition:  border 3s ease;
    -ms-transition: border 3s ease;
    transition: border 3s ease; 
}

.fadeit.load {
    border-bottom: '8px solid #E8C44C';

    -webkit-transition:  border 3s ease;
    -moz-transition:  border 3s ease;
    -o-transition:  border 3s ease;
    -ms-transition: border 3s ease;
    transition: border 3s ease; 
} */
/* 




.fadeit {
    opacity: 0;
    font-size: 21px;
    margin-top: 25px;
    text-align: center;

    -webkit-transition: opacity 2s ease-in;
    -moz-transition: opacity 2s ease-in;
     -ms-transition: opacity 2s ease-in;
      -o-transition: opacity 2s ease-in;
         transition: opacity 2s ease-in;
}

.fadeit.load {
    opacity: 1;

    -webkit-transition: opacity 2s ease-in;
       -moz-transition: opacity 2s ease-in;
        -ms-transition: opacity 2s ease-in;
         -o-transition: opacity 2s ease-in;
            transition: opacity 2s ease-in;
} */

.linklike {
  cursor: pointer;
  color: #531b8d;
  text-decoration: underline;
}

.adminSettings-checkbox-subradio.MuiFormControlLabel-root {
  margin-left: 2rem;
}

.MuiGrid-root.add-inspection-columns {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.custom-pc-table-main.MuiContainer-root {
  display: flex;
  flex-direction: column;
}

.custom-pc-table-pov {
  display: flex;
  height: min-content;
  width: 100%;
}

.custom-pc-table-information {
  display: flex;
  flex-direction: column;
  height: min-content;
  width: 100%;
}

.custom-pc-table-information .MuiPaper-root {
  display: flex;
}

.custom-pc-table-information p {
  align-self: center;
}

.custom-pc-table-information input.MuiInputBase-input {
  padding: 10px 14px;
}

.custom-pc-table-criteria {
  min-height: min-content;
  min-width: min-content;
  width: 100%;
}

.custom-pc-table-criteria table {
  border: 0.5px solid #e2e2e2;
  padding: 1rem;
  width: 100%;
  text-align: center;
  border-collapse: collapse;
}

.custom-pc-table-criteria td,
.custom-pc-table-criteria th {
  border: 0.5px solid #e2e2e2;
  padding: 1rem;
}
.citation-document-main
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.MuiInputBase-marginDense.MuiOutlinedInput-marginDense,
.citation-document-main .MuiInputBase-root.MuiOutlinedInput-root.edit {
  background-color: white;
}

.custom-pc-table-bottom-border {
  border-bottom: 0.5px solid #e2e2e2;
}
.button {
  border: 1px;
  color: white;
  padding: 10px 22px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 2px 2px;
  cursor: pointer;
  border-radius: 20%;
}
.button1 {background-color: #008CBA;} /* Blue */

em {
  float: right;
  color: #e05c65;
  padding-left: 10px;
  font-size: small;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.error input,
.error select,
.error textarea {
  background-color: #e3c3c5;
}
.error ::-webkit-input-placeholder {
  color: #999;
}
.error ::-moz-placeholder {
  color: #999;
}
.error :-moz-placeholder {
  color: #999;
}
.error :ms-input-placeholder {
  color: #999;
}


.transition-fade-enter {
  opacity: 0; }

.transition-fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in; }

.transition-fade-exit {
  opacity: 1; }

.transition-fade-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in; }



  a.darkbluelink,
  a.darkbluelink:link,
  a.darkbluelink:visited,
  a.darkbluelink:hover,
  a.darkbluelink:active
  {
    color: #ddcdcd;
  }

  .darkbluelinklike {
    cursor: pointer;
    color: #ddcdcd;
    text-decoration: underline;
  }